export const getMMProAddress = () => {
	return '0x6067490d05F3cF2fdFFC0e353b1f5Fd6E5CCDF70'
}
export const getUsdtAddress = () => {
	return '0x55d398326f99059fF775485246999027B3197955'
}

export const getPancakeAddress = () => {
	return '0x10ed43c718714eb63d5aa57b78b54704e256024e'
}
export const getPrivateSaleAddress = () => {
	return '0xD89409831aD4201fe886C19ffd428Cea927af87F'
}