export default () => {
	return (
		<svg width="240" height="52" viewBox="0 0 382 85" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_25_3805)">
				<path
					d="M86.0004 55.7999H80.1004L79.6004 43L72.2004 53.4H71.4004L64.0004 43L63.3004 55.7999H57.4004L58.8004 30.7H60.5004L71.7004 45.7L82.8004 30.7H84.5004L86.0004 55.7999Z"
					fill="black"/>
				<path
					d="M105.101 39.3999C106.401 40.5999 107.101 42.4999 107.101 45.0999V54.9999C103.901 55.8999 100.901 56.3999 98.2008 56.3999C92.3008 56.3999 89.3008 54.4998 89.3008 50.5998C89.3008 46.7998 92.3008 44.9999 97.3008 44.9999C98.3008 44.9999 99.7008 45.0999 101.701 45.3999V44.9999C101.701 43.7999 101.401 42.9999 100.701 42.5999C100.001 42.1999 98.9008 41.9999 97.4008 41.9999C95.6008 41.9999 93.7008 42.2999 91.7008 42.7999L91.1008 38.8999C93.8008 38.1999 96.2008 37.7999 98.5008 37.7999C101.601 37.6999 103.801 38.2999 105.101 39.3999ZM98.4008 52.3999C99.5008 52.3999 100.601 52.2999 101.701 52.1999V48.6999C100.301 48.4999 99.1008 48.3999 98.1008 48.3999C95.8008 48.3999 94.6008 49.0999 94.6008 50.3999C94.6008 51.7999 95.7008 52.3999 98.4008 52.3999Z"
					fill="black"/>
				<path
					d="M123.6 38.2L122.7 42.4C121.2 42.1 119.9 42 118.7 42.1C118.2 42.1 117.4 42.3 116.4 42.5V55.8H111V39.8C114.1 38.4 117.1 37.7 119.9 37.7C121.5 37.6 122.7 37.8 123.6 38.2Z"
					fill="black"/>
				<path
					d="M139.001 55.7999L134.001 47.7999H132.001V55.7999H126.601V28.5L132.001 28.2V43.5999H134.101L138.901 38.2H144.901L138.201 45.5L145.201 55.9H139.001V55.7999Z"
					fill="black"/>
				<path
					d="M151.301 48.4001C151.701 50.9001 153.501 52.1 156.701 52.1C158.301 52.1 160.301 51.8 162.701 51.3L163.301 54.8C162.601 55.2 161.501 55.5 160.201 55.8C158.901 56.1 157.501 56.3 156.101 56.3C153.001 56.3 150.501 55.4001 148.601 53.7001C146.801 52.0001 145.801 49.8 145.801 47.1C145.801 44.4 146.701 42.1001 148.501 40.4001C150.301 38.6001 152.601 37.8 155.501 37.8C158.201 37.8 160.301 38.6 162.001 40.1C163.701 41.7 164.501 43.7 164.501 46.1C164.501 46.9 164.401 47.7001 164.301 48.4001H151.301ZM159.201 44.9001C159.201 44.1001 158.901 43.3001 158.301 42.7001C157.701 42.1001 156.701 41.8 155.301 41.8C153.201 41.8 151.801 42.8001 151.301 44.9001H159.201Z"
					fill="black"/>
				<path
					d="M179.701 51.3999L180.101 55.1999C178.301 55.7999 176.501 56.0999 174.701 56.0999C173.101 56.0999 171.801 55.5999 170.801 54.6999C169.801 53.7999 169.401 52.2999 169.401 50.2999V42.0999H166.601L166.901 38.0999H169.401V33.5999L174.801 32.8999V38.0999H180.201L179.901 42.0999H174.801V49.6999C174.801 50.9999 175.301 51.4999 176.701 51.4999C177.601 51.5999 178.601 51.5999 179.701 51.3999Z"
					fill="black"/>
				<path
					d="M220.5 55.7999H214.6L214.1 43L206.7 53.4H205.9L198.6 43L197.9 55.7999H192L193.4 30.7H195.1L206.3 45.7L217.4 30.7H219.1L220.5 55.7999Z"
					fill="black"/>
				<path
					d="M239.601 39.3999C240.901 40.5999 241.601 42.4999 241.601 45.0999V54.9999C238.401 55.8999 235.401 56.3999 232.701 56.3999C226.801 56.3999 223.801 54.4998 223.801 50.5998C223.801 46.7998 226.801 44.9999 231.801 44.9999C232.801 44.9999 234.201 45.0999 236.201 45.3999V44.9999C236.201 43.7999 235.901 42.9999 235.201 42.5999C234.501 42.1999 233.401 41.9999 231.901 41.9999C230.101 41.9999 228.201 42.2999 226.201 42.7999L225.601 38.8999C228.301 38.1999 230.701 37.7999 233.001 37.7999C236.101 37.6999 238.301 38.2999 239.601 39.3999ZM232.901 52.3999C234.001 52.3999 235.101 52.2999 236.201 52.1999V48.6999C234.801 48.4999 233.601 48.3999 232.601 48.3999C230.301 48.3999 229.101 49.0999 229.101 50.3999C229.201 51.7999 230.301 52.3999 232.901 52.3999Z"
					fill="black"/>
				<path
					d="M258.1 55.7999L253.1 47.7999H251.1V55.7999H245.7V28.5L251.1 28.2V43.5999H253.2L258 38.2H264L257.3 45.5L264.3 55.9H258.1V55.7999Z"
					fill="black"/>
				<path d="M272.301 35.0999H266.801V29.7H272.301V35.0999ZM272.201 55.7999H266.801V38.2H272.201V55.7999Z"
				      fill="black"/>
				<path
					d="M294.2 44.3V55.8H288.8V45.5C288.8 44.2 288.6 43.3 288.3 42.8C287.9 42.3 287.2 42 286 42C284.7 42 283.4 42.2 281.9 42.5V55.9H276.5V39.9C279.8 38.5 283.1 37.8 286.6 37.8C291.4 37.7 294.2 40 294.2 44.3Z"
					fill="black"/>
				<path
					d="M316 57.8999C316 59.7999 315.1 61.3999 313.2 62.5999C311.3 63.7999 308.8 64.3999 305.6 64.3999C302.8 64.3999 300.5 63.8999 298.9 62.9999C297.2 61.9999 296.4 60.7999 296.4 59.2999C296.4 57.6999 297.3 56.3999 299 55.2999C298.4 54.6999 298 53.7999 298 52.7999C298 51.4999 298.7 50.3999 300.2 49.4999C298.7 48.2999 297.9 46.5999 297.9 44.4999C297.9 42.3999 298.7 40.6999 300.2 39.4999C301.7 38.2999 303.7 37.6999 306.2 37.6999C307.3 37.6999 308.4 37.7999 309.4 38.0999C310.1 35.2999 311.8 33.8999 314.4 33.8999H315.6L315.7 38.0999H314.6C313.5 38.0999 312.7 38.5999 312.3 39.5999C313.6 40.7999 314.3 42.3999 314.3 44.3999C314.3 46.4999 313.6 48.0999 312.1 49.2999C310.6 50.4999 308.6 51.1999 306.1 51.1999C305 51.1999 303.9 51.0999 303 50.7999C302.7 50.9999 302.6 51.2999 302.6 51.6999C302.6 52.1999 302.9 52.4999 303.6 52.5999C303.7 52.5999 304.6 52.6999 306.1 52.7999C307.6 52.9999 309.2 53.0999 310.7 53.2999C314.2 53.6999 316 55.1999 316 57.8999ZM310.6 58.5999C310.6 57.6999 310 57.2999 308.6 57.0999L302.6 56.4999C302 57.0999 301.6 57.6999 301.6 58.2999C301.6 59.5999 303 60.4999 305.9 60.4999C309 60.4999 310.6 59.7999 310.6 58.5999ZM303.3 44.4999C303.3 46.2999 304.4 47.3999 306 47.3999C307.8 47.3999 308.7 46.2999 308.7 44.4999C308.7 42.6999 307.8 41.7999 306 41.7999C304.2 41.6999 303.3 42.7999 303.3 44.4999Z"
					fill="black"/>
				<path
					d="M345.6 37.9999C345.6 40.6999 344.7 42.8999 342.8 44.5999C340.9 46.2999 338.2 47.1999 334.7 47.1999C333.8 47.1999 332.8 47.1999 331.6 47.0999V55.7999H328.2V30.7999C328.7 30.7999 330 30.6999 332.1 30.6999C334.2 30.5999 335.6 30.5999 336.3 30.5999C342.5 30.5999 345.7 32.9999 345.6 37.9999ZM342.1 38.1999C342.1 36.4999 341.6 35.2999 340.7 34.5999C339.8 33.8999 338.2 33.4999 335.9 33.4999C335.6 33.4999 334.1 33.5999 331.6 33.6999V43.9999C332.9 44.0999 334 44.0999 334.9 44.0999C339.7 44.0999 342.1 42.0999 342.1 38.1999Z"
					fill="black"/>
				<path
					d="M360.4 38.1L360.3 41.1C358.8 40.9 357.4 40.9 356.2 41C355.4 41.1 354.3 41.3 353 41.8V55.9001H349.7V40.1C351.3 39.3 352.8 38.7 354 38.3C355.2 38 356.6 37.8 358 37.8C358.4 37.8 359.2 37.9 360.4 38.1Z"
					fill="black"/>
				<path
					d="M365.2 53.5999C363.4 51.8999 362.5 49.6999 362.5 46.9999C362.5 44.3999 363.4 42.1999 365.3 40.4999C367.1 38.6999 369.4 37.8999 372.1 37.8999C374.9 37.8999 377.3 38.7999 379 40.4999C380.8 42.1999 381.7 44.3999 381.7 47.0999C381.7 49.6999 380.8 51.8999 378.9 53.5999C377 55.3999 374.8 56.1999 372.1 56.1999C369.2 56.1999 366.9 55.2999 365.2 53.5999ZM372.1 53.3999C373.9 53.3999 375.4 52.7999 376.6 51.5999C377.7 50.3999 378.3 48.8999 378.3 47.0999C378.3 45.2999 377.7 43.7999 376.6 42.5999C375.5 41.3999 374 40.7999 372.1 40.7999C370.2 40.7999 368.7 41.3999 367.6 42.5999C366.5 43.7999 365.9 45.2999 365.9 47.0999C365.9 48.8999 366.5 50.3999 367.6 51.5999C368.8 52.7999 370.3 53.3999 372.1 53.3999Z"
					fill="black"/>
				<path
					d="M37 29.1V18.7H36.4V29.1H32L25.3 35.8V15.8H21V0H20.4V15.8H16V35.8L9.3 29.1H4.9V18.7H4.3V29.1H0V56H4.3V70.6H4.9V56H9.3V40.2L16 46.9V63.9H20.4V84.1H21V63.9H25.3V46.9L32 40.2V56H36.4V70.6H37V56H41.3V29.1H37Z"
					fill="#33CC66"/>
			</g>
			<defs>
				<clipPath id="clip0_25_3805">
					<rect width="381.7" height="84.1" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	)
}